import React, { useEffect, useState } from 'react'
import { Introduccion } from './Introduccion'
import { Habilidades } from './Habilidades'
import { Resumen } from './Resumen'
import { Proyectos } from './Proyectos'
import { Servicios } from './Servicios'
import { Contacto } from './Contacto'
import { Navegacion } from './Navegacion'
import { Footer } from './Footer'
import { useParams } from 'react-router-dom'
import { SaveLocalStorage } from '../helpers/SaveLocalStorage'
import { Modal } from './Modal'
import { Credits } from './Credits'
import { Informacion } from './Informacion'

export const PortfolioDisplay = () => {


    let { PROFID = "ANDRESP01", LANG = "ES" } = useParams();
    

    const [cargando, setCargando] = useState(true);
    const [errores, setErrores] = useState("");
    const [detailsState,setDetailsState] = useState({});

    
    useEffect(() => {

        const getProfileAjaxPms = () => {
            let ApiURL = process.env.REACT_APP_PROFILEAPI;
            ApiURL += "?profile="+PROFID+LANG;
            fetch(ApiURL)
                .then(respuesta => respuesta.json())
                .then(
                    respuesta_final => {
                        localStorage.clear();
                        SaveLocalStorage("Portfolio", respuesta_final);
                        setDetailsState(respuesta_final.Projects.ProjectsList[0]);
                        setCargando(false);
                    },
                    error => {
                        console.log(error);
                        setErrores("Oops Ese perfil no existe! - Oops Profile Not Found!")
                    }
                )
        }
        getProfileAjaxPms();
    },[PROFID,LANG]);



    if (errores !== "") {
        // Cuando pasa algun error
        return (
            <div className='Errores'>
                <h2> Error</h2>
                {errores}
            </div>
        )
    } else if (cargando === true) {
        // Cuando todo esta cargando
        return (
            <div className="loading">
                <span className="loader"></span>
                <span className="loading-Caption">¡cargando tu perfil!</span>
            </div>
        )
    } else if (cargando === false && errores === "") {
        // Cuando todo salio bien
        return (
            <div className='layout'>
                <header id="header">
                    <Navegacion />
                </header>

                
                <div className="content">
                    <Introduccion />
                    <Informacion />
                    <Habilidades />
                    <Resumen />
                    <Proyectos detailsState={detailsState} setDetailsState={setDetailsState}/>
                    <Servicios />
                    <Contacto />
                    <Modal detailsState={detailsState} setDetailsState={setDetailsState}/>
                    <Credits/>
                </div>

                <Footer />
            </div>
        )
    }






}
