import React from 'react'
import { useNavigate } from 'react-router-dom';

export const Proyectos = ({detailsState, setDetailsState}) => {

  let Portfolio = JSON.parse(localStorage.getItem("Portfolio"))[0];
  let Projects = Portfolio.Projects;
  let InterfaceLang = Portfolio.InterfaceLanguaje;
  const navegar = useNavigate();

  const setDetailsContent = (Details,Title,Descript,Image,Link) =>{
    let detailsContent = {
      Details,
      Title,
      Descript,
      Image,
      Link
    }
    setDetailsState(detailsContent);
    navegar("#detailsmodal");
  }

  return (
    
    <section id="portafolio" className="proyectos section-bg reveal">

      <div className="container section-content">

        <div className="section-title">
          <h2>{Projects.Title}</h2>
          <p>{Projects.Descript}</p>
        </div>

        <div className="row proyectos-container">

        {Projects.ProjectsList != null ? Projects.ProjectsList.map(Project =>{
          return(
            <div key={Project.Name} className="col-lg-4 col-md-6 proyectos-item filter-web">
            <div className="proyectos-wrap">
              <img src={Project.Image} className="img-fluid" alt=""/>
              <div className="proyectos-info">
                <h4>{Project.Name}</h4>
                <p>{Project.Type}</p>
                <span className='descripcion'>{Project.Descript}</span>
                <div className="proyectos-links">
                  <a href='#detailsmodal' onClick={ e=> setDetailsContent(Project.Details,Project.Name,Project.Descript,Project.Image,Project.Link)} data-gallery="proyectosGallery" className="proyectos-lightbox btn btn-primary" title="Details">{InterfaceLang.ProjectsButton}</a>
                </div>
              </div>
            </div>
            </div>
          )
        }):
          <h1>Nothing to show</h1>
        }

        </div>

      </div>
    </section>
  )
}
