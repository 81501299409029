import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom';

export const Contacto = () => {

    let Portfolio = JSON.parse(localStorage.getItem("Portfolio"))[0];
    let Contact = Portfolio.Contact;
    let InterfaceLang = Portfolio.InterfaceLanguaje;
    let { PROFID} = useParams();

    const contactForm = useRef();

    const [cargando, setCargando] = useState(false);
    const [exito, setExito] = useState(false);
    const [errores, setErrores] = useState(false);

    const setContactQueryAjaxPms = e => {
        e.preventDefault();

        let form = contactForm.current;
        
        let forminfo = {
            name: form.firstName.value,
            lastname:form.lastName.value,
            email: form.email.value,
            phone: form.phone.value,
            message: form.message.value,
            myemail: Contact.Email,
            profid: PROFID,
        }

        
        let ApiURL = "https://script.google.com/macros/s/AKfycbyf7wKudT3AeEfrBjWoqPmOk_bfHDWD3-omBPeFZYU57_qzQZpp1KOCp0CQ5heJhR7lOw/exec";
        ApiURL += "?cform="+JSON.stringify(forminfo);
        setCargando(true);
        fetch(ApiURL,{method: "POST"})
            .then(respuesta => respuesta)
            .then(
                respuesta_final => {
                    setCargando(false);
                    setExito(true)

                },
                error => {
                    setCargando(false);
                    setErrores(true)
                    console.log(error);
                }
            )
            

    }

    if (cargando) {
        contactForm.current.classList.add("d-none")
    }



    return (

        <section id="contacto" className="contact">
            <div className="container section-content section-contacto">
                <div className="section-title">
                    <h2>{Contact.Title}</h2>
                    <p></p>
                </div>

                <div className="contact__wrapper shadow-lg mt-n9">
                    <div className="row no-gutters">
                        <div className="col-lg-5 contact-info__wrapper gradient-brand-color order-lg-2">
                            <h2 className="color--white mb-5 contact-title">{Contact.Descript}</h2>

                            <ul className="contact-info__list list-style-contact position-relative z-index-101">
                                <li className="mb-4 ">
                                    <i className="bi bi-envelope-paper-heart-fill"></i><span className="position-absolute">&nbsp; {Contact.Email}</span>
                                </li>
                                <li className="mb-4 ">
                                    <i className="bi bi-phone-vibrate-fill"></i><span className="position-absolute">&nbsp; {Contact.Phone}</span>
                                </li>
                                <li className="mb-4 ">
                                    <i className="bi bi-linkedin" ></i><span className="position-absolute">&nbsp; <a className="link-style-none" target="_blank" rel='noreferrer' href={Contact.LinkedinLink}>linkedin Click!</a></span>
                                </li>



                                <li className="mb-4 ">
                                    <i className="bi bi-person-heart"></i><span className="position-absolute">&nbsp; {Contact.Name}</span>
                                </li>
                            </ul>


                        </div>
                        <div className="col-lg-7 contact-form__wrapper p-5 order-lg-1" >

                            {cargando && (
                                <div className='d-flex w-100 h-100 align-content-center justify-content-center flex-wrap' >
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            )}
                            {exito && (
                                <div className='d-flex w-100 h-100 align-content-center justify-content-center flex-wrap' >
                                    <div className="alert alert-success d-flex align-items-center" role="alert">
                                        <i className="bi bi-envelope-check-fill flex-shrink-0 me-4"> </i>
                                        <div>
                                            {InterfaceLang.SuccesAlert}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {errores && (
                                <div className='d-flex w-100 h-100 align-content-center justify-content-center flex-wrap' >
                                    <div className="alert alert-danger d-flex align-items-center" role="alert">
                                        <i className="bi bi-x-octagon-fill flex-shrink-0 me-4"></i>
                                        <div>
                                            {InterfaceLang.ErrorAlert[0]}
                                            <a href={InterfaceLang.ErrorReportLink} rel='noreferrer' className="alert-link" target='_blank'>{InterfaceLang.ErrorAlert[1]}</a>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <form onSubmit={setContactQueryAjaxPms} className="contact-form form-validate needs-validation" ref={contactForm}>
                                <div className="row">
                                    <div className="col-sm-6 mb-3">
                                        <div className="form-group">
                                            <label className="required-field mb-2" htmlFor="firstName">{Contact.Form.Name}</label>
                                            <input type="text" className="form-control" id="firstName" name="firstName" placeholder={Contact.Form.NamePlaceholder} required/>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                        <div className="mb-3">
                                            <label className="mb-2" htmlFor="lastName">{Contact.Form.Lastname}</label>
                                            <input type="text" className="form-control" id="lastName" name="lastName" placeholder={Contact.Form.LastnamePlaceholder} />
                                        </div>
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                        <div className="mb-3">
                                            <label className="required-field mb-2" htmlFor="email">{Contact.Form.Email}</label>
                                            <input type="email" className="form-control" id="email" name="email" placeholder={Contact.Form.EmailPlaceholder} required/>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 mb-3">
                                        <div className="mb-3">
                                            <label className="mb-2" htmlFor="phone">{Contact.Form.Phone}</label>
                                            <input type="tel" className="form-control" id="phone" name="phone" placeholder={Contact.Form.PhonePlaceholder} />
                                        </div>
                                    </div>

                                    <div className="col-sm-12 mb-3">
                                        <div className="mb-3">
                                            <label className="required-field mb-2" htmlFor="message">{Contact.Form.Textarea}</label>
                                            <textarea className="form-control" id="message" name="message" rows="4" placeholder={Contact.Form.TextareaPlaceholder} required></textarea>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 mb-3">
                                        <button type="submit" className="btn btn-primary">{Contact.Button}</button>

                                    </div>

                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>


        </section>
    )
}
