import React from 'react'

export const Footer = () => {
    let Portfolio = JSON.parse(localStorage.getItem("Portfolio"))[0];
    let InterfaceLang = Portfolio.InterfaceLanguaje;



  return (
    <footer id="footer">
    <div className="w-100 bg-950 py-3">
        <div className="container">
            <div className="row pt-2">
                <div className="col-lg-6 col-sm-12">
                    <p className="text-lg-start text-center text-light light-300">© Copyright 2024 Say3D! {InterfaceLang.FooterCredits[0]}</p>
                </div>
                <div className="col-lg-6 col-sm-12">
                    <p className="text-lg-end text-center text-light light-300">{InterfaceLang.FooterCredits[1]}&nbsp;<a rel='noreferrer' className="text-decoration-none text-light" href="http://say3d.com.co" target="_blank" ><strong>Say3D! software &nbsp;</strong></a>{InterfaceLang.FooterCredits[2]}&nbsp;<a rel="noreferrer" className="text-decoration-none text-light" href="#creditsmodal"><strong>BootstrapMade</strong></a></p>
                </div>
            </div>
        </div>
    </div>
</footer>
  )
}
