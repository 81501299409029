import React from 'react'

export const Habilidades = () => {

  let Portfolio = JSON.parse(localStorage.getItem("Portfolio"))[0];
  let Skills = Portfolio.Skills;



  return (
    <div>
      <section id="habilidades" className="habilidades section-bg reveal ">
        <div className="container section-content">

          <div className="section-title">
            <h2>{Skills.Title}</h2>
            <p>{Skills.Descript}</p>
          </div>

          <div className="row habilidades-content reveal align-items-center">
            <div className="col-lg-4">
              {Skills.SkillsList != null ? Skills.SkillsList.map(Skill => {
                return (
                  <div key={Skill.SkillName} className="progress">
                    <span className="habilidad">{Skill.SkillName}<i className="val">{Skill.Level}</i></span>
                    <div className="progress-bar-wrap">
                      <div className="progress-bar progress-fill" role="progressbar" aria-valuenow={Skill.Percentaje} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                )

              }) :
                <h1>Nothing to show</h1>
              }
            </div>
            <div className="col-lg-4">
              {Skills.SkillsList2 != null ? Skills.SkillsList2.map(Skill => {
                return (
                  <div key={Skill.SkillName} className="progress">
                    <span className="habilidad">{Skill.SkillName}<i className="val">{Skill.Level}</i></span>
                    <div className="progress-bar-wrap">
                      <div className="progress-bar progress-fill" role="progressbar" aria-valuenow={Skill.Percentaje} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                )

              }) :
                <h1>Nothing to show</h1>
              }
            </div>


            <div className="col-lg-4 blandas">
              {(Skills.SoftSkills) != null ? (Skills.SoftSkills).map(SoftSkill => {
                
                    return (
                      <ul key={SoftSkill.Title}>
                        <li>
                          <i className="bi bi-caret-right"></i> <strong>{SoftSkill.Title}</strong>
                        </li>
                      </ul>
                    )
                
                  }) : <h1>Sin Objetos para mostrar</h1>
                  }
            </div>

          </div>
        </div>
      </section>
    </div>
  )
}
