import React from 'react'

export const Informacion = () => {

    let Portfolio = JSON.parse(localStorage.getItem("Portfolio"))[0];
    let Information = Portfolio.Introduction;

    return (
        <div>
            <div>
                <section id="informacion" className="informacion reveal">
                    <div className='container section-content'>

                        <div className="section-title">
                            <h2>{Information.Title}</h2>
                            <p>{Information.Intro}</p>
                        </div>

                        <div className="row">

                            <div className="content">

                                <div className="row">
                                    <div className="col-lg-6">
                                    {(Information.MyData) != null ? (Information.MyData).map(ListItem => {
                                            return (
                                                <ul key={ListItem.Title}>
                                                    <li>
                                                        <i className="bi bi-caret-right"></i> <strong>{ListItem.Title}</strong> <span>{ListItem.Desc}</span>
                                                    </li>
                                                </ul>
                                            )
                                        }) : <h1>Nothing to show</h1>
                                        }
                                    </div>
                                    <div className="col-lg-6">
                                    <h3>{Information.Curriculum.Title}</h3>
                                <p>{Information.Curriculum.Descript}</p>
                                <a className='btn btn-primary' href={Information.Curriculum.Link} target='_blank'  rel='noreferrer'>{Information.Curriculum.BtnDescript}</a>
                                <a className='btn btn-primary' href={Information.Curriculum.Linkedin} target='_blank'  rel='noreferrer'><i className="bi bi-linkedin"></i></a>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </div>
    )
}
