import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));





function reveal() {
    var reveals = document.querySelectorAll(".reveal");
  
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }


function fill() {
    var reveals = document.querySelectorAll(".progress-fill");
  
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 0;
  
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("w-"+reveals[i].getAttribute("aria-valuenow"));
      } else {
        reveals[i].classList.remove("w-"+reveals[i].getAttribute("aria-valuenow"));
      }
    }
  }  
  
  window.addEventListener("scroll", reveal);
  window.addEventListener("scroll", fill);








root.render(
    <App />
);
