import React from 'react'
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { PortfolioDisplay } from '../components/PortfolioDisplay';

export const RouterPrincipal = () => {



    return (
        <BrowserRouter>



                <Routes>
                    <Route path='/' element={<PortfolioDisplay/>} />
                    <Route path='/perfil/:PROFID/:LANG' element={<PortfolioDisplay/>} />

                    <Route path='*' element={"Nothing"} />
                </Routes>


        </BrowserRouter>
    )
}
