import React from 'react'

export const Introduccion = () => {


  let Portfolio = JSON.parse(localStorage.getItem("Portfolio"))[0];
  let Introduction = Portfolio.Introduction;

  return (
    <div>
          <section className="introduccion " id="introduccion" style={{ 
          backgroundImage: ` linear-gradient(to bottom, var(--color-transparency) 90% ,var(--color-50) 99%),url(${Introduction.ProfilePic})`
        }}>
            <div className="container">

              <div className="row">
                <div className="col-md-12 text-right navicon">
                  <a id="nav-toggle" className="nav_slide_button" href="index.html#"><span></span></a>
                </div>
              </div>

              <div className="row">
                <div className="col-md-8 col-md-offset-1 inner">
                  <h1 className="">
                  {Introduction.Name}
                  </h1>
                  <h3 className=""><span className="rotate">{Introduction.Typed}</span></h3>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-md-offset-3 text-center">
                  <a href="#informacion" className="">
			            <p className="scrollto--arrow arrowrev"><i className=" bi bi-arrow-down-circle-fill" alt="scroll down arrow"></i></p>
			        </a>
                </div>
              </div>
            </div>
          </section>
    </div>
  )
}
