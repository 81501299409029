import React from 'react'

export const Resumen = () => {

  let Portfolio = JSON.parse(localStorage.getItem("Portfolio"))[0];
  let Resume = Portfolio.Resume;



  return (
    <div>
    <section id="resumen" className="resumen reveal">
      <div className="container section-content">

        <div className="section-title">
          <h2>{Resume.Title}</h2>
          <p>{Resume.Descript}</p>
        </div>

        <div className="row">
          <div className="col-lg-6 reveal">


            <h3 className="resumen-title">{Resume.TitleSummary}</h3>
            <div className="resumen-item pb-0">
              <h4>{Resume.Summary.FullName}</h4>
              <p><em>{Resume.Summary.Descript}</em></p>
              <ul>
                <li>{Resume.Summary.Location}</li>
                <li>{Resume.Summary.Phone}</li>
                <li>{Resume.Summary.Email}</li>
              </ul>
            </div>

            <h3 className="resumen-title">{Resume.TitleEducation}</h3>
            {Resume.Education != null ? Resume.Education.map(EduItem =>{
              return (              
              <div key={EduItem.Title} className="resumen-item">
              <h4>{EduItem.Title}</h4>
              <h5>{EduItem.Dates}</h5>
              <p><em>{EduItem.Institute}</em></p>
              <p>{EduItem.Descript}</p>
              <a rel='noreferrer' target="_blank" href={EduItem.Link}>{EduItem.LinkDescript}</a>
              </div>
            )
            }):
              <h1>Nothing to show</h1>
            }
          </div>


          <div className="col-lg-6 reveal">
            <h3 className="resumen-title">{Resume.TitleExperience}</h3>
            {Resume.Experience != null ? Resume.Experience.map(ExpItem =>{
              return (
              <div key={ExpItem.Company} className="resumen-item">
              <h4>{ExpItem.Role}</h4>
              <h5>{ExpItem.Dates}</h5>
                <p><em>{ExpItem.Company}</em></p>
                <ul>
                  {ExpItem.Descript != null ? ExpItem.Descript.map(DescriptItem =>{
                    return(
                      <li key={"Li"+DescriptItem}>{DescriptItem}</li>
                    )

                  }):
                    <h1>Nothing to show</h1>
                  }
                </ul>
              </div>
  
              )
            }):
              <h1>Nothing to show</h1>
            }
          </div>


        </div>

      </div>
    </section>
    </div>
  )
}
