import React from 'react'

export const Credits = () => {
  return (
    <div id='creditsmodal' className="modalmask">

      <div className="modalbox movedown">
        <a href="#close" title="Close" className="close"><i className="bi bi-x-lg"></i></a>

        <div className='d-flex justify-content-beetween'>


          <div id="modal-info" className="modal-info" >
            <div className="d-flex p-2">
              <div className="section-title" >
                <h2>Credits</h2>


                <p > it was created by Andres Rojas, using as inspiration but NOT copying, some bootstrapmade designs, as you can see is not a copy from any template but inspires in this website designs so here is the page, they really do a great job so i hope you can find some helpful to you :D</p>
                <hr></hr>
                <a rel="noreferrer" className="text-decoration-none" href="https://www.bootstrapmade.com" target='_blank'>Click here! <strong>BootstrapMade</strong></a>




              </div>

            </div>
          </div>


        </div>

      </div>
    </div>
  )
}
