import React from 'react'

export const Modal = ({ detailsState, setDetailsState }) => {


    let Portfolio = JSON.parse(localStorage.getItem("Portfolio"))[0];
    let InterfaceLang = Portfolio.InterfaceLanguaje;


    if (detailsState != null) {
        return (
            <div>
                <div id="detailsmodal" className="modalmask" href="#close" title="Close" >
                    <div className="modalbox movedown">
                        <a href="#close" title="Close" className="close"><i className="bi bi-x-lg"></i></a>

                        <div className='modalrow d-flex justify-content-beetween'>

                            <div id="modal-img" className='modal-img'>
                                <img src={detailsState.Image} className="img-fluid" alt="" />
                                <p className='modal-img-desc'>{detailsState.Descript}</p>
                                {detailsState.Link && (
                                    <div>
                                    <a className='btn btn-primary' href={detailsState.Link} target='blank'>{InterfaceLang.ProjectsDownloadButton}</a>
                                    </div>
                                )}

                            </div>



                            <div id="modal-info" className="modal-info" >
                                <div className="d-flex p-2">

                                    <div className=''>
                                        <div className="section-title" >
                                            <h2>{detailsState.Title}</h2>
                                            

                                            {(detailsState.Details.DetailedDescript) != null ? (detailsState.Details.DetailedDescript).map(ListItem => {
                                                            return (
                                                                <p key={ListItem} style={{padding: "6px"}}>{ListItem}</p>
                                                            )
                                                        }) : <h1>Sin Objetos para mostrar</h1>
                                                        }


                                            
                                        </div>

                                        <div className="row">
                                            <div className=" content">
                                                <div className="row">

                                                    <div className="col-lg">

                                                        {(detailsState.Details.DetailsList) != null ? (detailsState.Details.DetailsList).map(ListItem => {
                                                            return (
                                                                <ul key={ListItem.Title}>
                                                                    <li >
                                                                        <i className="bi bi-caret-right"></i> <strong>{ListItem.Title}</strong><span>{ListItem.Descript}</span>
                                                                    </li>
                                                                </ul>
                                                            )
                                                        }) : <h1>Sin Objetos para mostrar</h1>
                                                        }


                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        )
    } else {
        <div id="detailsmodal" className="modalmask">Nothing to show</div>
    }




}
