
import { RouterPrincipal } from "./routers/RouterPrincipal";

function App() {

  return(
    <RouterPrincipal/>
  )

}


export default App;
