export const SaveLocalStorage = (key, element) => {

    //Conseguir los elementos del localStorage
    let elements = JSON.parse(localStorage.getItem(key));

    //Comprobar si es un array (si ya existe contenido)
    if (Array.isArray(elements)){
        //añade un elemento nuevo
        elements.push(element);
    } else{
        //si no hay contenido crear un array y añadir una elemento nuevo
        elements = [element];
    }

    //Guardar el nuevo contenido
    localStorage.setItem(key, JSON.stringify(elements));

    //Devolver un objeto guardado
    return elements;

    

}