import React, { useRef } from 'react'
import { Idiomas } from './Idiomas'


export const Navegacion = () => {

  let Portfolio = JSON.parse(localStorage.getItem("Portfolio"))[0];
  let InterfaceLang = Portfolio.InterfaceLanguaje;

  const mobileNav = useRef();

  const toggleNav = () => {
    mobileNav.current.classList.toggle("open")
  }

  return (
    <div ref={mobileNav}>
      <div id="hamburger-icon" className="closed " onClick={toggleNav}>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
      <div className='divnavegacion'>

        <nav id="navegacion" className="navegacion" >
          <ul>
            <li><a href="#informacion" className="nav-link"><i className="bi bi-house-heart"></i> <span>{InterfaceLang.Navbar.IntroText}</span></a></li>
            <li><a href="#habilidades" className="nav-link"><i className="bi bi-alt"></i> <span>{InterfaceLang.Navbar.SkillsText}</span></a></li>
            <li><a href="#resumen" className="nav-link"><i className="bi bi-card-checklist"></i> <span>{InterfaceLang.Navbar.ResumeText}</span></a></li>
            <li><a href="#portafolio" className="nav-link"><i className="bi bi-box-seam"></i> <span>{InterfaceLang.Navbar.ProjectsText}</span></a></li>
            <li><a href="#servicios" className="nav-link"><i className="bi bi-window-stack"></i> <span>{InterfaceLang.Navbar.ServicesText}</span></a></li>
            <li><a href="#contacto" className="nav-link"><i className="bi bi-person-add"></i> <span>{InterfaceLang.Navbar.ContactText}</span></a></li>
          </ul>
        </nav>
        <Idiomas />
      </div>

    </div>
  )
}
