import React from 'react'

export const Servicios = () => {

  let Portfolio = JSON.parse(localStorage.getItem("Portfolio"))[0];
  let Services = Portfolio.Services;

  return (

    <section id="servicios" className="services">
      <div className="container section-content reveal" >

        <div className="section-title">
          <h2>{Services.Title}</h2>
          <p>{Services.Descript}</p>
        </div>

        <div className="row">
          {Services.ServicesList != null ? Services.ServicesList.map(Service => {
            return(
              <div key={Service.ServiceName} className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div className="icon-box iconbox-blue">
                  <div  className="icon">
                    <i className={Service.Icon}></i>
                  </div>
                  <h4><a href={Service.ServiceLink}>{Service.ServiceName}</a></h4>
                  <p>{Service.ServiceDescript}</p>
                </div>
              </div>
            )
          }):
            <h1>Nothing to show</h1>
          }
        </div>

      </div>
    </section>
  )
}
