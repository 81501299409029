import React from 'react'
import {useParams } from 'react-router-dom';


export const Idiomas = () => {

  let { PROFID = "ANDRESP01" } = useParams();

  return (
    <div className="dropdown">
    <button className="btn btn-light btnidioma dropdown-toggle" type="button-lg" data-bs-toggle="dropdown" aria-expanded="false">
      Idioma / Language 
    </button>
    <ul className="dropdown-menu">
      <li ><a href={`/perfil/${PROFID}/ES`} className="dropdown-item" ><img alt='Language' className="lang-image" src="https://purecatamphetamine.github.io/country-flag-icons/3x2/CO.svg" height="15rem" ></img>&nbsp; Español  </a></li>
      <li ><a href={`/perfil/${PROFID}/EN`} className="dropdown-item" ><img alt='Language' className="lang-image" src="https://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg" height="15rem"></img>&nbsp; English  </a></li>
    </ul>
  </div>
  )
}
